import LayoutLeagueSoul from '@/layout/league/worker/soul'

//团学工作
export default {
    path: '/league/worker/soul',
    component: LayoutLeagueSoul,
    redirect: '/league/worker/soul',
    alwaysShow: true,
    meta: {
        title: '网上共青团',
    },
    children: [
        {
            path: '',
            component: () => import('@/views/league/worker/soul'),
            // name: 'league-worker',
            meta: {
                title: '首页',
            }
        },
        {
            path: ':view',
            component: () => import('@/views/league/worker/soul'),
            meta: {
                title: '菜单栏目'
            }
        },
        {
            path: ':view/:aid',
            component: () => import('@/views/league/worker/soul'),
            meta: {
                title: '文章详细'
            }
        },
        // {
        //     path: 'dynamic',
        //     component: () => import('@/views/league/soul/dynamic'),
        //     // name: 'league-group',
        //     meta: {
        //         title: '中心动态'
        //     }
        // },
        // {
        //     path: 'notice',
        //     component: () => import('@/views/league/soul/notice'),
        //     // name: 'league-group',
        //     meta: {
        //         title: '最新通知'
        //     }
        // },
        // {
        //     path: 'consultation',
        //     component: () => import('@/views/league/soul/consultation'),
        //     // name: 'league-group',
        //     meta: {
        //         title: '心理咨询师'
        //     }
        // },
        // {
        //     path: 'video',
        //     component: () => import('@/views/league/soul/video'),
        //     // name: 'league-group',
        //     meta: {
        //         title: '心理视频'
        //     }
        // },
        // {
        //     path: 'hotline',
        //     component: () => import('@/views/league/soul/hotline'),
        //     // name: 'league-group',
        //     meta: {
        //         title: '心理危机热线'
        //     }
        // },
        // {
        //     path: 'links',
        //     component: () => import('@/views/league/soul/links'),
        //     // name: 'league-group',
        //     meta: {
        //         title: '友情链接'
        //     }
        // },
    ]
};
