import Layout from '@/layout'

//新闻资讯
const newsInformation = {
    path: '/news',
    component: Layout,
    redirect: '/news/journalism',
    meta: {
        title: '新闻资讯',
        icon: 'excel'
    },
    children: [{
            path: 'journalism',
            component: () => import('@/views/news/index'),
            meta: {
                title: '校园新闻'
            }
        },
        {
            path: 'journalism/:aid',
            component: () => import('@/views/news/index'),
            meta: {
                title: '校园新闻'
            }
        },
        {
            path: 'notice',
            component: () => import('@/views/news/index'),
            meta: {
                title: '通知公告'
            }
        },
        {
            path: 'notice/:aid',
            component: () => import('@/views/news/index'),
            meta: {
                title: '通知公告'
            }
        },
        {
            path: 'department',
            component: () => import('@/views/news/index'),
            meta: {
                title: '部门动态'
            }
        },
        {
            path: 'department/:aid',
            component: () => import('@/views/news/index'),
            meta: {
                title: '部门动态'
            }
        },
        {
            path: 'open',
            component: () => import('@/views/news/index'),
            meta: {
                title: '信息公开'
            }
        },
        {
            path: 'open/:name',
            component: () => import('@/views/news/index'),
            meta: {
                title: '信息公开'
            }
        },
        {
            path: 'open/:name/:aid',
            component: () => import('@/views/news/index'),
            meta: {
                title: '信息公开'
            }
        },
        {
            path: 'video',
            component: () => import('@/views/news/index'),
            meta: {
                title: '视频信息'
            }
        },
        {
            path: 'video/:aid',
            component: () => import('@/views/news/index'),
            meta: {
                title: '视频信息'
            }
        }

    ]
};
//
export default newsInformation