import Layout from '@/layout'

//就业创业
const employmentEntrepreneurship = {
    path: '/employment',
    component: Layout,
    redirect: '/employment/policy',
    meta: {
        title: '就业创业',
    },
    children: [
        {
            path: ':view',
            component: () => import('@/views/employment/index'),
            meta: { 
                title: '列表'
            }
        },
        {
            path: ':view/:aid',
            component: () => import('@/views/employment/index'),
            meta: { 
                title: '详细'
            }
        },
        // {
        //     path: 'policy',
        //     component: () => import('@/views/employment/index'),
        //     meta: { 
        //         title: '创业政策'
        //     }
        // },
        // {
        //     path: 'practice',
        //     component: () => import('@/views/employment/index'),
        //     meta: { 
        //         title: '创业实践'
        //     }
        // },
        // {
        //     path: 'study',
        //     component: () => import('@/views/employment/index'),
        //     meta: { 
        //         title: '创业学习'
        //     }
        // },
        // {
        //     path: 'character',
        //     component: () => import('@/views/employment/index'),
        //     meta: { 
        //         title: '创业人物风采'
        //     }
        // },
        // {
        //     path: 'report',
        //     component: () => import('@/views/employment/index'),
        //     meta: { 
        //         title: '创业系列报道'
        //     }
        // },
        // {
        //     path: 'website',
        //     component: () => import('@/views/employment/index'),
        //     meta: { 
        //         title: '就业网'
        //     }
        // },
    ]
};
//
export default employmentEntrepreneurship