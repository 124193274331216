<template>
<header>
    <div class='main'>
    <ul class='main-left'>
        <li class='logo'><img src='@/assets/imgs/logo-header.png' id='header-logo' /></li> 
        <li class='ident'>
        <span>学校代码8636</span>
        <span>(招生期间)</span>
        </li>
    </ul>
    <ul class='main-right'>
        <li class='flag'><img src='@/assets/imgs/flag.png' id='header-motto' /></li>
        <li class='more'>
            <div class='top'>
                <!-- <li class='name' @click="_clickPath('user/login')" v-if="!user.viewname">登录</li>
                <li class='logout' @click="_userLogout" v-else>
                <span class='viewicon'><svg-icon icon-class="x-power" /></span>
                <span class='viewname' v-text="user.viewname"></span>
                </li>
                <li class='line'></li> -->
                <li class='name' @click="_addFavorite">加入收藏</li>
                <!-- <li class='line'></li> -->
                <!-- <li class='name' @click="_setHomePage($event)">设为首页</li> -->
            </div>
            <div class='bot'>
                <ul class='search'>
                <li class='txt'><van-field v-model="search.name" placeholder="请输入关键字" /></li>
                <li class='btn' @click="_searchArticle()"><svg-icon icon-class="x-search" /></li>
                </ul>
            </div>
        </li>
    </ul>
    </div>
    <div class='menu'>
        <div class='cont'>
        <span :class='x.css' @mouseover="_clickMenu(x.path,$event)" v-for="x in menu" v-text='x.title' :key="x"></span>
        </div>
    </div>
</header>
<div class='content'>
    <router-view :key="key" />
</div>
<footer>
    <div class='main'>
    <ul class='main-left'><li class='logo'><img src='@/assets/imgs/logo-footer.png' id='footer-logo' /></li></ul>
    <ul class='main-center'>
        <li>
        <span v-text="_textItem(system.sponsor,'')"></span>
        <span v-text="_textItem(system.undertaker,'')"></span>
        </li>
        <li>
        <span v-text="_textItem(system.address,'')"></span>
        <span v-text="_textItem(system.postal_code,'')"></span>
        </li>
        <li>
        <span>备案号: 赣ICP备20201229-1</span>
        <span class='click-href' @click="_clickFiling()">备案系统查询链接</span>
        </li>
    </ul>
    <ul class='main-center1'>
        <li>
        <span>就业工作监督投诉举报</span>
        </li>
        <li>
        <span>电话：0791-86765295、0791-86765297</span>
        </li>
        <li>
        <span>邮箱：jxjygz@163.com</span>
        </li>
        <li>
        <span class='click-href' @click="employmentFn()">就业平台链接</span>
        </li>
    </ul>
    <ul class='main-right1'>
        <li class='ident'><img src='../assets/imgs/foot-img.png'/></li>
    </ul>
    <ul class='main-right'>
        <li class='ident'><img src='@/assets/imgs/blue.png' id='footer-motto' /></li>
    </ul>
    </div>
</footer>
<div class='menu-slide' v-show="select.show" ref="menuShow">
    <li class='space'></li>
    <li class='list'>
    <span class='item' v-for="x in select.list" v-text="x.title" @click="_clickSelect(x)"></span>
    </li>
</div>
<FloatService />
<PopupPlayer ref="viewVideo" />
</template>
<script>
/* eslint-disable */
import FloatService  from '@/components/float'
import PopupPlayer from '@/components/video/popup'
import vant from 'vant';
export default {
    data(){
        return {
            menu:[
                {css:'',path:'',title:'首页'},
                {css:'',path:'profile',title:'学院概况'},
                {css:'',path:'news',title:'新闻资讯'},
                {css:'',path:'party',title:'党建工作'},
                {css:'',path:'scientific',title:'教学科研'},
                {css:'',path:'college',title:'二级学院'},
                {css:'',path:'league',title:'学生工作'},
                {css:'',path:'enrollment',title:'招生信息'},
                {css:'',path:'employment',title:'就业创业'},
                {css:'',path:'special',title:'专题专栏'}
            ],
            view:{
                //学院概况
                profile:[
                    {path:'profile/introduce',title:'学院简介'},
                    {path:'profile/constitution',title:'学院章程'},
                    {path:'profile/identification',title:'学院标识'},
                    {path:'profile/leader',title:'现任领导'},
                    {path:'profile/mechanism',title:'组织机构'},
                    {path:'profile/history',title:'历史沿革'},
                    {path:'profile/history',title:'大事记'},
                    {path:'profile/honor',title:'荣誉奖项'},
                    {path:'profile/scenery',title:'校园风光'}
                ],
                //党建工作
                party:[
                    {path:'party/education',title:'理论学习'},
                    {path:'party/building',title:'党建之窗'},
                    {path:'party/uphold',title:'廉政建设'},
                    {path:'party/ideological',title:'思政工作'},
                ],
                //教学科研
                scientific:[],
                //二级学院
                college:[
                    {path:'college/biology',title:'生物工程学院'},
                    {path:'college/animal',title:'动物科学学院'},
                    {path:'college/medicine',title:'中医药学院'},
                    {path:'college/economics',title:'经济管理学院'},
                    {path:'college/information',title:'信息工程学院'},
                    {path:'college/electrical',title:'机电工程学院'},
                    {path:'college/preschool',title:'幼儿师范学院'}
                ],
                //团学工作
                league:[
                    {path:'league/worker',title:'学工在线'},
                    {path:'league/group',title:'网上共青团'},
                ],
                //招生信息
                enrollment:[],
                //就业创业
                employment:[
                    {path:'employment/recruit',title:'招聘信息'},
                    {path:'employment/policy',title:'就业政策'},
                    {path:'employment/job',title:'求职技巧'},
                    {path:'employment/service',title:'服务指南'},
                    {path:'employment/cooperation',title:'校企合作'},
                    {path:'employment/education',title:'创业教育'}
                ],
                //新闻资讯
                news:[
                    {path:'news/journalism',title:'校园新闻'},
                    {path:'news/notice',title:'通知公告'},
                    {path:'news/video',title:'视频信息'},
                    {path:'news/open',title:'信息公开'},
                    {path:'news/department',title:'部门动态'}

                ],
                //专题专栏
                special:[]
            },
            select:{
                show:false,
                list:[],
                view:null
            },
            user:{},
            system:{},
            search:{
                name:null
            }
        }
    },
    components: {
        FloatService,PopupPlayer
    },
    computed: {
        key() {
            return this.$route.path
        }
    },
    provide(){
        let that = this;
        return {
            providePlayerVideo(x){
                if(x.link){
                    let link = x.link.indexOf('http')===0?x.link:'http://'+x.link;
                    return window.open(link,'_bank')
                }
                if(!x.files || !u.isArray(x.files)){
                    return;
                }
                let url = x.files[0];
                that.$refs.viewVideo.playerVideo(url);
            }
        }
    },
    methods: {
        _userLogout(){
            u.post('user/logout',{},(ret)=>{
                if(ret.code!=200){
                    return;
                }
                vant.Toast.success('成功退出');
                this.user = {};
                u.store('user',{});
            });
        },
        _addFavorite(){
            u.addFavorite();
        },
        _setHomePage(e){
            let el = e.currentTarget;
            u.setHomePage(el)
        },
        _clickPath(name){
            u.href(name);
        },
        _initMenu(name){
            let list = this.menu;
            for(let i in list){
                let v = list[i];
                v.css='';
                if(v.path==name || (name && v.path && name.indexOf(v.path)===0)){
                    v.css='active';
                    continue;
                }
            }
        },
        _clickMenu(name,e){
            let list = this.view[name];
            this._initMenu(name)
            if(list){
                return this._viewMenu(e,list)
            }
            u.href(name);
        },
        _clickSelect(x){
            if(x.type=='link'){
                let url = x.path.indexOf('http')===0?x.path:'http://'+x.path;
                return window.open(url,'_bank')
            }
            let path = x.path;
            u.href(path);
            this.select.show = false;
        },
        _clickFiling(){
            let url = 'https://beian.miit.gov.cn/state/outPortal#/Integrated/recordQuery'
            window.open(url,'_bank');
        },
        employmentFn(){
            let url = 'https://www.jxaevc.com/#/employment/policy'
            window.open(url,'_bank');
        },
        ///////////////////////////////////////////////////////////////
        _viewMenu(e,list){
            let select = this.select;
            // if(select.show){
            //     select.list = [];
            //     return select.show = false;
            // }
            select.list = [];
            for(let i in list){
                let v = list[i];
                select.list.push(v)
            }
            //-----
            this.select.show = true;
            let el = e.currentTarget;
            let si = this.$refs.menuShow;
            si.style.top = (el.offsetTop)+'px';
            si.style.left= (el.offsetLeft)+'px';
            si.style.width = el.offsetWidth + 'px';
            //-----
            this._hoverView(el,si,select);
        },
        _hoverView(el,si,x){
            u.addCls(el,'menu-view');
            u.__init_mouse_status = false;
            let resetShow = ()=>{
                setTimeout(()=>{
                    x.show = u.__init_mouse_status;
                    if(!x.show){
                        u.removeCls(el,'menu-view');
                    }
                },100);
            }
            el.onmouseout = ()=>{
                u.__init_mouse_status = false;
                resetShow();
            }
            si.onmouseover = ()=>{
                u.__init_mouse_status= true;
            }
            si.onmouseout = ()=>{
                u.__init_mouse_status = false;
                resetShow();
            };
        },
        _searchArticle(){
            let S = this.search;
            if(!S.name){
                return vant.Toast.fail('请输入搜索内容');
            }
            let query = S.name.replace('?','');
            if(window.location.hash.indexOf('search')===2){
                u.href('search?'+query);
                return window.location.reload(true);
            }
            u.href('search?'+query);
        },
        //////////////////////////////////////////////////////////////
        //初始化主题
        _initSystemData(){
            let arr = window.location.hash.split('?')
            let key = arr[1] || null;
            u.post('site/system',{
                __key__:key,
                path:'main'
            },(ret)=>{
                if(ret.code!=200){
                    return;
                }
                let D = ret.data || {};
                D.header_color = D.header_color || '43,111,207';
                D.body_color = D.body_color || '255,255,255';
                D.footer_color = D.footer_color || '23,81,137';
                this.system = D;
                u.systemSetting(D,this);
                u.data.banner = D.banner || [];
            })
        },
        _textItem(name,def){
            return name?name:def;
        },
        _initLoad(){
            //教学科研
            u.post('menu/childList',{
                path:'scientific'
            },(ret)=>{
                if(ret.code!=200){
                    return;
                }
                let list = ret.list || [];
                let menu = [];
                for(let i in list){
                    let v = list[i];
                    menu.push({
                        title:v.name,
                        path:v.url,
                        type:v.type
                    });
                }
                ///////////////////////////////////////////////////////
                this.view.scientific = menu;
            },true)

            //招生信息
            u.post('menu/childList',{
                path:'enrollment'
            },(ret)=>{
                if(ret.code!=200){
                    return;
                }
                let list = ret.list || [];
                let menu = [];
                for(let i in list){
                    let v = list[i];
                    menu.push({
                        title:v.name,
                        path:v.url,
                        type:v.type
                    });
                }
                ///////////////////////////////////////////////////////
                this.view.enrollment = menu;
            },true)

            //专题专栏
            u.post('menu/childList',{
                path:'special'
            },(ret)=>{
                if(ret.code!=200){
                    return;
                }
                let list = ret.list || [];
                let menu = [];
                for(let i in list){
                    let v = list[i];
                    if(v.name=='疫情防控'){
                        continue;
                    }
                    menu.push({
                        title:v.name,
                        path:v.url,
                        type:v.type
                    });
                }
                ///////////////////////////////////////////////////////
                this.view.special = menu;
            },true)
        }
    },
    created: function(){
        this._initLoad();
        let path = this.$route.path.substr(1);
        this._initMenu(path)
        /*************************/
        this._initSystemData(); 
    }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/layout.scss';
.menu-view{
    background-color: rgba(255,255,255,.2);
}
/*********************************
 MENU-SLIDE ///////////////////
*/
.menu-slide{
    position:absolute;
    top:0;
    left:0;
    z-index:1000;
    display: block;
    overflow: hidden;
    min-width: 110px;
    text-align: left;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    overflow-y: auto;
    max-height: 100%;
}
.menu-slide .space{
    height: 45px;
    display: block;
    overflow: hidden;
    width: 100%;
    background-color: rgba(0,0,0,0);
}
.menu-slide .list{
    color:rgba($headerColor,1);
    background:rgba(255,255,255,0.95);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 100%;
    display: block;
    overflow: hidden;
    .item{
        height: 36px;
        line-height: 36px;
        padding-left: 8px;
        padding-right: 8px;
        border-bottom: 1px solid rgba(153,153,153,0.1);
        cursor: pointer;
        font-size:14px;
        /***********/
        display: block;
        overflow: hidden;
        text-overflow : ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
    .item:hover {
        background-color: #f5f8fa;
    }
}
.menu-slide .active{
    background-color: #f5f8fa;
}
.menu-slide .active a{
    color:#578f7b;
}
.click-href:hover{
    cursor: pointer;
    opacity: .8;
}
</style>
