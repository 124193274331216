<template>
<div id="app">
    <router-view />
</div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'App'
}
</script>
