// import { createApp } from 'vue'
import {createRouter,createWebHashHistory} from 'vue-router'

/* Layout */
import Layout from '@/layout'

//用户
import User from './modules/user'
//学院概况
import CollegeProfile from './modules/profile'
//党建工作
import PartyBuilding from './modules/party'
//教学科研
import TeachingScientific from './modules/scientific'
//二级学院
import SecondaryCollege from './modules/college'
//招生信息
import EnrollmentInformation from './modules/enrollment'
//就业创业
import EmploymentEntrepreneurship from './modules/employment'
//新闻资讯
import NewsInformation from './modules/news'
//专题专栏
import SpecialColumn from './modules/special'
/**************************************************** LEAGUE **********/
//团学工作-学生在线
import LeagueWorker from './modules/league/worker'
//团学工作-网上共青团
import LeagueGroup from './modules/league/group'
//团学工作-学生在线-心灵之家
import LeagueWorkerSoul from './modules/league/worker/soul'
//
export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index')
            }
        ]
    },
    {
        path: '/404',
        component: () => import('@/views/error/404'),
        hidden: true
    },
    {
        path: '/',
        component: Layout,
        redirect: '/',
        //
        children: [
            {
                path: '',
                component: () => import('@/views/home/index'),
                name: 'home',
                meta: { 
                    title: '首页', 
                }
            },
            {
                path: 'search',
                component: () => import('@/views/search'),
                meta: { 
                    title: '搜索', 
                }
            },
            {
                path: 'search/senior',
                component: () => import('@/views/search/senior'),
                meta: { 
                    title: '搜索', 
                }
            },
            {
                path: 'service/email',
                component: () => import('@/views/service/email'),
                hidden: true
            },
        ]
    },
    User,
    CollegeProfile,
    PartyBuilding,
    TeachingScientific,
    EnrollmentInformation,
    EmploymentEntrepreneurship,
    NewsInformation,
    SpecialColumn,
    SecondaryCollege,
    LeagueWorker,
    LeagueWorkerSoul,
    LeagueGroup,
]
//
const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes, // short for `routes: routes`
})
export default router
