import Layout from '@/layout'

//学院概况
const collegeProfile = {
    path: '/profile',
    component: Layout,
    redirect: '/profile/introduce',
    alwaysShow: true, // will always show the root menu
    meta: { 
        title: '学院概况',
    },
    children: [
        {
            path: 'introduce',
            component: () => import('@/views/profile/index'),
            meta: { 
                title: '学院简介' 
            }
        },
        {
            path: 'constitution',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '学院章程'
            }
        },
        {
            path: 'identification',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '学院标识'
            }
        },
        {
            path: 'leader',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '现任领导'
            }
        },
        {
            path: 'mechanism',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '机构设置'
            }
        },
        {
            path: 'history',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '历史沿革'
            }
        },
        //////////////////////////////////////////////////////
        {
            path: 'memorabilia',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '大事记'
            }
        },
        {
            path: 'memorabilia/:aid',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '大事记'
            }
        },
        //////////////////////////////////////////////////////
        {
            path: 'honor',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '荣誉奖项'
            }
        },
        {
            path: 'scenery',
            component: () => import('@/views/profile/index'),
            meta: {
                title: '校园风光'
            }
        },
    ]
};
//
export default collegeProfile