import Layout from '@/layout'

//专题专栏
const specialColumn = {
    path: '/special',
    component: Layout,
    redirect: '/special/column',
    alwaysShow: true,
    meta: { 
        title: '专题专栏',
    },
    children: [
        ////////////////////////////////////////////////////////////////////
        {
            path: 'column',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '专题专栏' 
            }
        },
        {
            path: 'column/:cid',
            component: () => import('@/views/special/column/current'),
            meta: { 
                title: '专题专栏--详细' 
            }
        },
        {
            path: 'column/notice/:aid',
            component: () => import('@/views/special/column/current'),
            meta: { 
                title: '通知公告--列表' 
            }
        },

        {
            path: 'column/progress/:aid',
            component: () => import('@/views/special/column/current'),
            meta: { 
                title: '工作进展--列表' 
            }
        },

        {
            path: 'column/media/:aid',
            component: () => import('@/views/special/column/current'),
            meta: { 
                title: '媒体之声--列表' 
            }
        },

        {
            path: 'column/briefing/:aid',
            component: () => import('@/views/special/column/current'),
            meta: { 
                title: '工作简报--列表' 
            }
        },
        {
            path: 'column/history',
            component: () => import('@/views/special/column/history'),
            meta: { 
                title: '历史专题', 
            }
        },
        {
            path: 'column/history/:aid',
            component: () => import('@/views/special/column/history'),
            meta: { 
                title: '历史专题', 
            }
        },
        ////////////////////////////////////////////////////////////////////
        {
            path: 'safety',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: 'safety/:name',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: 'safety/:name/:aid',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: 'staff',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: 'staff/:name',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: 'staff/:name/:aid',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: 'epidemic',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: 'epidemic/:name',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: 'epidemic/:name/:aid',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: ':view',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: ':view/:aid',
            component: () => import('@/views/special/index'),
            meta: { 
                title: '详细' 
            }
        },
    ]
};
//
export default specialColumn