<template>
<div class="float-service">
    <ul class='item'>
    <span class='icon'><svg-icon icon-class="x-float-0" /></span>
    <span class='text' @click="_clickPath('enrollment/consultation')">消息</span>
    </ul>
    <ul class='item'>
    <span class='icon'><svg-icon icon-class="x-float-1" /></span>
    <span class='text' @click="_clickConsole()">管理后台</span>
    </ul>
    <ul class='item'>
    <span class='icon'><svg-icon icon-class="x-float-2" /></span>
    <span class='text'>通知公告</span>
    </ul>
    <ul class='item'>
    <span class='icon'><svg-icon icon-class="x-float-3" /></span>
    <span class='text' @click="_clickPath('special/column/history')">历史专题</span>
    </ul>
    <ul class='item'>
    <span class='icon'><svg-icon icon-class="x-float-4" /></span>
    <span class='text' @click="_clickPath('scientific/information')">教学信息</span>
    </ul>
</div>
</template>

<script>
/* eslint-disable */
export default {
    methods: {
        _clickConsole(){
            let url = u.AdminUrl;
            u.href(url)
        },
        _clickPath(name){
            u.href(name)
        }
    }
}
</script>

<style lang="scss" scoped>
/********************** vi-ascending
 * FLOAT-SERVICE
 */
.float-service{
    position: fixed;
    z-index: 999;
    bottom:160px;
    right:0px;
    width: 80px;
    display: block;
    overflow: hidden;
    background-color: rgba(0,0,0,0);
}
.float-service .item{
    width:50%;
    margin-left:50%;
    height:40px;
    display: block;
    overflow: hidden;
    margin-top:5px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #e2f1fe;
    color: #6a93c8;
}
.float-service .item:first-child{
    margin-top:0px;
}
.float-service .item .icon{
    display: inline-block;
    font-size:24px;
}
.float-service .item .text{
    display: none;
    font-size:12px;
    position: relative;
    top:-3px;
    left:3px;
}
.float-service .item:hover{
    margin-left:0px;
    width:100%;
    background-color: #266cd2;
    color: #fff;
    .icon{
        font-size:20px;
    }
    .text{
        display: inline-block;
    }
}


</style>
