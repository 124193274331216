import LayoutLeagueWorker from '@/layout/league/worker'

//团学工作
export default {
    path: '/league/worker',
    component: LayoutLeagueWorker,
    redirect: '/league/worker',
    alwaysShow: true,
    meta: {
        title: '学工在线',
    },
    children: [
        {
            path: '',
            component: () => import('@/views/league/worker'),
            meta: {
                title: '首页',
            }
        },
        {
            path: ':view',
            component: () => import('@/views/league/worker'),
            meta: {
                title: '菜单栏目'
            }
        },
        {
            path: ':view/:aid',
            component: () => import('@/views/league/worker'),
            meta: {
                title: '文章详细'
            }
        },
        // {
        //     path: 'notice',
        //     component: () => import('@/views/league/worker'),
        //     meta: {
        //         title: '通知公告'
        //     }
        // },
        // {
        //     path: 'dynamic',
        //     component: () => import('@/views/league/worker'),
        //     meta: {
        //         title: '学工动态'
        //     }
        // },
        // {
        //     path: 'document',
        //     component: () => import('@/views/league/worker'),
        //     meta: {
        //         title: '政策文件'
        //     }
        // },
        // {
        //     path: 'support',
        //     component: () => import('@/views/league/worker'),
        //     meta: {
        //         title: '学生资助'
        //     }
        // },
        // {
        //     path: 'soul',
        //     component: () => import('@/views/league/worker'),
        //     meta: {
        //         title: '心灵之家'
        //     }
        // },
        // {
        //     path: 'download',
        //     component: () => import('@/views/league/worker'),
        //     meta: {
        //         title: '下载专区'
        //     }
        // },
    ]
};
