<template>
<div >
    <router-view :key="key" />
</div>
</template>
<script>
/* eslint-disable */
export default {
    data(){
        return {

        }
    },
    computed: {
        key() {
            return this.$route.path
        }
    },
    methods: {
 
    }
}
</script>
<style lang="scss" scoped>

</style>
