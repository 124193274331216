<template>
<div class='LayoutCollege'>
<header>
    <div class='main'>
    <ul class='main-left'>
        <li class='logo'><img src='@/assets/imgs/logo-footer.png' id='header-logo' /></li> 
        <li class='ident'>学工在线</li>
    </ul>
    <ul class='main-right'>
        <li class='more'>
            <div class='top'>
                <!-- <li class='name' @click="_clickPath('user/login')" v-if="!user.viewname">登录</li>
                <li class='logout' @click="_userLogout" v-else>
                <span class='viewicon'><svg-icon icon-class="x-power" /></span>
                <span class='viewname' v-text="user.viewname"></span>
                </li>
                <li class='line'></li> -->
                <li class='name' @click="_addFavorite">加入收藏</li>
                <li class='line'></li>
                <li class='name' @click="_clickPath()">学院首页</li>
            </div>
            <div class='bot'>
                <ul class='search'>
                <li class='txt'><van-field v-model="search.name" placeholder="请输入关键字" /></li>
                <li class='btn' @click="_searchArticle()"><svg-icon icon-class="x-search" /></li>
                </ul>
            </div>
        </li>
    </ul>
    </div>
    <div class='menu'>
        <div class='cont'>
        <span :class='x.css' @click="_switchWorker(x)" v-for="x in menu" v-text='x.name' :key="x"></span>
        </div>
    </div>
    <div class='banner'><div class='main' id='system-banner'></div></div>
</header>
<div class='content'>
    <div class='router'>
        <div class='navigation' v-if="path">
            <ul class='navigation'>
            <li class='name' @click="_clickPath()">首页</li>
            <li class='icon'>&gt;</li>
            <li class='name'>团学工作</li>
            <li class='icon'>&gt;</li>
            <li class='name' @click="_clickPath('league/worker')">学工在线</li>
            <li class='icon'>&gt;</li>
            <li class='name' v-text="name"></li>
            </ul>
        </div>
        <router-view :key="key" />
    </div>
</div>
<footer>
    <div class='main'>
    <ul class='main-left'><li class='logo'><img src='@/assets/imgs/logo-footer.png' id='footer-logo' /></li></ul>
    <ul class='main-center'>
        <li>
        <span v-text="_textItem(system.sponsor,'')"></span>
        <span v-text="_textItem(system.undertaker,'')"></span>
        </li>
        <li>
        <span v-text="_textItem(system.address,'')"></span>
        <span v-text="_textItem(system.postal_code,'')"></span>
        </li>
        <li>
        <span>备案号: 赣ICP备20201229-1</span>
        <span class='click-href' @click="_clickFiling()">备案系统查询链接</span>
        </li>
    </ul>
    <ul class='main-right'>
        <li class='ident'><img src='@/assets/imgs/blue.png' id='footer-motto' /></li>
    </ul>
    </div>
</footer>
</div>
<FloatService />
</template>
<script>
/* eslint-disable */
import FloatService  from '@/components/float'
import vant from 'vant';
export default {
    data(){
        return {
            menu:[
                {css:'',path:'',name:'首页'},
                {css:'',path:'notice',name:'通知公告'},
                {css:'',path:'dynamic',name:'学工动态'},
                {css:'',path:'document',name:'政策文件'},
                {css:'',path:'support',name:'学生资助'},
                {css:'',path:'soul',name:'心灵之家'},
                {css:'',path:'download',name:'下载专区'}
            ],
            name:null,
            path:null,
            user:{},
            system:{},
            search:{
                name:null
            }
        }
    },
    components: {
        FloatService
    },
    computed: {
        key() {
            return this.$route.path
        }
    },
    methods: {
        _userLogout(){
            u.post('user/logout',{},(ret)=>{
                if(ret.code!=200){
                    return;
                }
                vant.Toast.success('成功退出');
                this.user = {};
                u.store('user',{});
            });
        },
        _clickPath(path){
            u.href(path);
        },
        _addFavorite(){
            u.addFavorite();
        },
        _switchActive(path){
            path = path || '';
            let menu = this.menu;
            for(let i in menu){
                let v = menu[i];
                v.css='';
                if(v.path==path){
                    v.css='active';
                    this.name = v.name;
                    this.path = v.path;
                }
            }
        },
        _switchWorker(x){
            this._switchActive(x.path);
            this.$router.push({
                path:'/league/worker'+(x.path?'/'+x.path:'')
            });
        },
        _searchArticle(){
            let S = this.search;
            if(!S.name){
                return vant.Toast.fail('请输入搜索内容');
            }
            let query = S.name.replace('?','');
            u.href('search?'+query);
        },
        _clickFiling(){
            let url = 'https://beian.miit.gov.cn/state/outPortal#/Integrated/recordQuery'
            window.open(url,'_bank');
        },
        //////////////////////////////////////////////////////////////
        //初始化主题
        _initSystemData(){
            let arr = window.location.hash.split('?')
            let key = arr[1] || null;
            u.post('site/system',{
                 __key__:key,
                path:'league/worker'
            },(ret)=>{
                if(ret.code!=200){
                    return;
                }
                let D = ret.data || {};
                D.header_color = D.header_color || '23,81,137';
                D.body_color = D.body_color || '246,245,246';
                D.footer_color = D.footer_color || '23,81,137';
                this.system = D;
                u.systemSetting(D,this);
            })
        },
        _textItem(name,def){
            return name?name:def;
        }
    },
    created: function(){
        this.path = u.hashID(2);
        this._switchActive(this.path);
        /*************************/
        this._initSystemData();
    }
}
</script>
<style lang="scss" scoped>
/*====================================
 HEADER
 -------------------
*/
header{ 
    position: relative; 
    z-index: 666;
    width:100%;
    background-color:rgba($headerColor,1);
    text-align: center;
    overflow: hidden;
    display: block;
    /*---------------------*/
    justify-content: space-between; 
    -webkit-justify-content: space-between; 
    align-items: center; 
    -webkit-align-items: center; 
    flex-wrap: wrap; 
    -webkit-flex-wrap: wrap;
}
header *{
    list-style: none;
    padding:0;
    margin:0;
}
/***************************************** 
 ---MODULE--TOP---- 
 */
header .main{
    height: 96px;
    color:#575f65;
    font-size: 12px;
    display: inline-block;
    overflow: hidden;
    width:$baseWidth;
}
header .main .main-left{
    float:left;
}
header .main .main-left .logo{
    float:left;
    margin-left:8px;
    margin-top:22px;
}
header .main .main-left .ident{
    float:left;
    display: inline-block;
    overflow: hidden;
    margin-left:20px;
    height:36px;
    line-height: 36px;
    margin-top:30px;
    text-align:left;
    color:#fff;
    font-family:"STKaiti","KaiTi";
    font-size:32px;
    font-weight: bolder;
    position: relative;
}
header .main .main-left .ident:before{
    position: relative;
    top:5px;
    content: '';
    height:30px;
    width: 2px;
    margin-right: 20px;
    background-color: rgb(255,255,255,0.2);
    display: inline-block;
    overflow: hidden;
}
header .main .main-left .logo img{
    height:54px;
    width: auto;
}
header .main .main-right{
    float:right;
}
header .main .main-right .more{
    float:left;
    width:260px;
    margin-left:20px;
}
header .main .main-right .more .top{
    display: block;
    overflow: hidden;
    height: 25px;
    font-size:12px;
    margin-top:16px;
}
header .main .main-right .more .top .name{
    float:right;
    display: block;
    overflow: hidden;
    color:#d8dde3;
    cursor: pointer;
}
header .main .main-right .more .top .username{
    float:right;
    display: block;
    overflow: hidden;
    color:rgba(255,255,255,.8);
}
/************************************************/
header .main .main-right .more .top .logout{
    float:right;
    display: block;
    overflow: hidden;
    color:#d8dde3;
    cursor: pointer;
    position: relative;
    height: 18px;
    border-radius: 5px;
    padding-left:5px;
    padding-right:3px;
    font-size:12px;
}
header .main .main-right .more .top .logout:hover{
    background-color: rgba(0,0,0,.2);
}
header .main .main-right .more .top .logout .viewname{
    display: inline-block;
    overflow: hidden;
    float: right;
}
header .main .main-right .more .top .logout .viewicon{
    display: inline-block;
    overflow: hidden;
    float: right;
    height: 20px;
    width: 12px;
    margin-left: 6px;
}
/************************************************/
header .main .main-right .more .top .line{
    float:right;
    display: block;
    overflow: hidden;
    margin-left:15px;
    margin-right:15px;
    height:12px;
    width: 1px;
    background-color: #d8dde3;
    margin-top:3px;
}
header .main .main-right .more .top .name:hover{
    color:rgba(255,255,255,0.6);
};
header .main .main-right .more .bot{
    display: block;
    overflow: hidden;
}
header .main .main-right .more .bot .search{
    float:right;
    height:28px;
    width:220px;
    outline: 0;
    margin-top: 12px;
}
header .main .main-right .more .bot .search .txt{
    width:calc(100% - 58px);
    height:28px;
    float:left;
    margin-left:5px;
    text-align: left;
    border:1px solid rgb(0,0,0,0.3);
    border-right:0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #fff;
}
header .main .main-right .more .bot .search .txt .van-field{
    border:0;
    height:22px;
    position: relative;
    top:2px;
    width:calc(100% - 10px);
    margin-left:5px;
    font-size:12px;
    padding-left: 10px;
}
header .main .main-right .more .bot .search .btn{
    display: block;
    overflow: hidden;
    float:right;
    width:53px;
    height:100%;
    background-color: rgb(255,255,255,0.2);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    line-height: 30px;
    color:#fff;
    cursor: pointer;
}
header .main .main-right .more .bot .search .btn svg{
    font-weight: bolder;
    font-size: 16px;
    position: relative;
    top:1px;
}
header .main .main-right .more .bot .search .btn:hover{
    background-color: rgb(255,255,255,0.3);
    color:#f5f8fa;
}
/***************/
header .menu{
    display: block;
    overflow: hidden;
    width: 100%;
    background-color: rgba($headerColor,1);
    height:47px;
    line-height: 46px;
    border-top: 1px solid rgb(255,255,255,0.2);
}
header .menu .cont{
    display: inline-flex;
    overflow: hidden;
    width:$baseWidth;
    color:#d8dde3;
    text-align: left;
}
header .menu .cont span{
    float:left;
    display: block;
    overflow: hidden;
    color:#fff;
    font-size: 16px;
    width: 16%;
    margin-left:7%;
    text-align: center;
    cursor: pointer;
    height: 46px;
}
header .menu .cont span:first-child{
    width: 15%;
    margin-left:0;
}
header .menu .cont span:hover{
    background-color: rgb(255,255,255,0.3);
    font-weight: bolder;
}
header .menu .cont .active{
    background-color: rgb(255,255,255,0.2);
    font-weight: bolder;
}
header .menu .cont .active:hover{
    background-color: rgb(255,255,255,0.2);
}
header .banner{
    display: block;
    overflow: hidden;
    width: 100%;
    text-align: center;
    height:400px;
}
.banner .main{
    width:100%;
    min-width: $baseWidth;
    max-width: 1920px;
    height: 100%;
    text-align: center;
    overflow: hidden;
    display: inline-block;
    background:url(@/assets/banner/league-worker.png) no-repeat 50% 0px;
    background-size: 1920px 100%;
}
/*====================================
 CONTENT
 -------------------
*/
.content{
    display: block;
    overflow: hidden;
    width: 100%;
    text-align: center;
    background-color: rgba($bodyColor,1);
}
.content .router{
    display: inline-block;
    overflow: hidden;
    width: $baseWidth;
}
/*====================================
 FOOTER
 -------------------
*/
footer{ 
    width: 100%;
    position: relative; 
    background-color:rgba($footerColor,1);
    text-align: center;
    overflow: hidden;
    color:#fff;
    border-top:1px solid rgb(255,255,255,0.6);
    height: 150px;
    /********/
    justify-content: space-between; 
    -webkit-justify-content: space-between; 
    align-items: center; 
    -webkit-align-items: center; 
    flex-wrap: wrap; 
    -webkit-flex-wrap: wrap;
}
footer *{
    list-style: none;
    padding:0;
    margin:0;
}
/***************************************** 
 ---MODULE--MANY---- 
 */
footer .main{
    color:#ffffff;
    font-size: 12px;
    display: inline-block;
    overflow: hidden;
    width:$baseWidth;
}
footer .main-left{
    float:left;
    width:30%;
}
footer .main-left .logo{
    float:left;
    margin-left:10px;
    margin-top:50px;
}
footer .main-left .logo img{
    height:55px;
    width: auto;
}
footer .main-center{
    float:left;
    width:40%;
    margin-top:30px;
    font-weight: initial;
}
footer .main-center li{
    display: block;
    overflow: hidden;
    height: 30px;
    line-height: 30px;
}
footer .main-center li span{
    display: inline-block;
    overflow: hidden;
    margin-right:15px;
}
footer .main-right{
    float:right;
    width:30%;
}
footer .main-right .ident{
    margin-top:35px;
    text-align: right;
}
footer .main-right .ident img{
    width:80px;
    margin-right: 30px;
}

/******************************/
.navigation{
    height: 30px;
    line-height: 20px;
    width: $baseWidth;
    display: block;
    overflow: hidden;
    margin-top:10px;
    margin-bottom: 10px;
}
.navigation li{
    float: left;
}
.navigation .icon{
    color: #8e8e8e;
    padding-left:8px;
    padding-right:8px;
    font-size:12px;
}
.navigation .name{
    color: #9a9a9a;
    cursor: pointer;
    font-size:16px;
}
.navigation .name:hover{
    color:$headerColor;
}
.click-href:hover{
    cursor: pointer;
    opacity: .8;
}
</style>
