import {createApp} from 'vue'
import vant from 'vant'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import '@/components/common/engine'

/* eslint-disable */

//创建APP
const app = createApp(App);

//添加SBG-ICON
const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./icons/svg', false, /\.svg$/)
requireAll(req)
//
import svgicon from '@/components/svgicon'

app.component('svg-icon', svgicon)

app.use(vant.Icon)
app.use(vant.Popup)
app.use(vant.Field)
app.use(vant.Pagination)
app.use(vant.Swipe)
app.use(vant.SwipeItem)
app.use(ElementPlus)
///////////////////////////
app.use(router).mount('#app');
window.app = app;

