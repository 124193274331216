import LayoutCollege from '@/layout/college'

//二级学院
const secondaryCollege = {
    path: '/college',
    component: LayoutCollege,
    redirect: '/college',
    alwaysShow: true,
    meta: {
        title: '二级学院',
    },
    children: [
        {
            path: ':name',
            component: () => import('@/views/college'),
            meta: { 
                title: '首页'
            }
        },
        {
            path: ':name/:view',
            component: () => import('@/views/college'),
            // name: 'college-home',
            meta: { 
                title: ''
            }
        },
        {
            path: ':name/:view/:aid',
            component: () => import('@/views/college'),
            meta: { 
                title: ''
            }
        },
        // {
        //     path: 'biology',
        //     component: () => import('@/views/college'),
        //     meta: { 
        //         title: '生物工程学院'
        //     }
        // },
        // {
        //     path: 'animal',
        //     component: () => import('@/views/college'),
        //     meta: { 
        //         title: '动物科学学院'
        //     }
        // },
        // {
        //     path: 'medicine',
        //     component: () => import('@/views/college'),
        //     meta: { 
        //         title: '中医药学院'
        //     }
        // },
        // {
        //     path: 'economics',
        //     component: () => import('@/views/college'),
        //     meta: { 
        //         title: '经济管理学院'
        //     }
        // },
        // {
        //     path: 'information',
        //     component: () => import('@/views/college'),
        //     meta: { 
        //         title: '信息工程学院'
        //     }
        // },
        // {
        //     path: 'electrical',
        //     component: () => import('@/views/college'),
        //     meta: { 
        //         title: '机电工程学院'
        //     }
        // },
        // {
        //     path: 'preschool',
        //     component: () => import('@/views/college'),
        //     meta: { 
        //         title: '幼儿师范学院'
        //     }
        // },
    ]
};
//
export default secondaryCollege