import LayoutLeagueGroup from '@/layout/league/group'

//团学工作
export default {
    path: '/league/group',
    component: LayoutLeagueGroup,
    redirect: '/league/group',
    alwaysShow: true,
    meta: {
        title: '网上共青团',
    },
    children: [
        {
            path: '',
            component: () => import('@/views/league/group'),
            meta: {
                title: '首页',
            }
        },
        {
            path: ':view',
            component: () => import('@/views/league/group'),
            meta: {
                title: '菜单栏目'
            }
        },
        {
            path: ':view/:aid',
            component: () => import('@/views/league/group'),
            meta: {
                title: '文章详细'
            }
        },
        // {
        //     path: 'many',
        //     component: () => import('@/views/league/group/many'),
        //     meta: {
        //         title: '三会二制一课'
        //     }
        // },
        // {
        //     path: 'activity',
        //     component: () => import('@/views/league/group/activity'),
        //     meta: {
        //         title: '校园文化活动'
        //     }
        // },
        // {
        //     path: 'voluntary',
        //     component: () => import('@/views/league/group/voluntary'),
        //     meta: {
        //         title: '志愿服务'
        //     }
        // },
        // {
        //     path: 'style',
        //     component: () => import('@/views/league/group/style'),
        //     meta: {
        //         title: '风采展示'
        //     }
        // },
    ]
};
