<template>
    <van-popup style="width:800px;" v-model:show="popup.show" :close-on-click-overlay="false" class='popup-video'
        @click-close-icon="closeVideo()" closeable close-icon-position="top-right">
        <!--     <div class='popup-head'>
        <ul class="head-close" @click="closeVideo"><van-icon name="cross" /></ul>
    </div> -->
        <VideoPlayer :url="popup.video" ref="playerVideo"></VideoPlayer>
    </van-popup>
</template>
<script>
/* eslint-disable */
import VideoPlayer from '@/components/video/player'
//////////////////////////////////////////////
export default {
    components: {
        VideoPlayer
    },
    props: {

    },
    data() {
        return {
            popup: {
                show: false,
                video: '',
            }
        }
    },
    methods: {
        ////////////////////////////////////////////////////////////////
        playerVideo(url) {
            this.popup.show = true;
            // if(this.popup.video===null){
            //     return this.popup.video = url;
            // }
            this.$nextTick(() => {
                this.$refs.playerVideo.onPlayer(url);
            })
        },
        closeVideo() {
            this.popup.show = false;
            this.popup.video = null;
            this.$refs.playerVideo.onClose();
        }
    },
    created() {

    }
}
</script>
<style lang="scss" scoped>
.popup-video {
    // min-width: 360px;
    /*    min-height:360px;*/
    width: 500px;
    height: 100%;
    margin: 0 auto;
    background-color: #000;
}

.popup-head {
    height: 30px;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    display: block;
}

.popup-head .head-close {
    float: right;
    font-size: 25px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    overflow: hidden;
    cursor: pointer;
    color: #3f4b5d;
}

.popup-head .head-close van-icon:before {
    font-weight: bolder;
}

.popup-head .head-close:hover {
    color: #1585ff;
}
</style>