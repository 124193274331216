import LayoutEnrollment from '@/layout/enrollment'

//招生信息 path: 'edit/:id(\\d+)',
const enrollmentInformation = {
    path: '/enrollment',
    component: LayoutEnrollment,
    redirect: '/enrollment',
    meta: {
        title: '首页',
    },
    children: [
        {
            path: '',
            component: () => import('@/views/enrollment/home'),
            meta: { 
                title: '首页'
            }
        },
        {
            path: 'information',
            component: () => import('@/views/enrollment/information'),
            meta: { 
                title: '招生信息'
            }
        },
        {
            path: 'brochure',
            component: () => import('@/views/enrollment/brochure'),
            meta: { 
                title: '招生简章', 
            },
        },
        {
            path: 'professional',
            component: () => import('@/views/enrollment/professional'),
            meta: { 
                title: '专业介绍'
            }
        },
        {
            path: 'consultation',
            component: () => import('@/views/enrollment/consultation'),
            meta: { 
                title: '考生咨询'
            }
        },
        {
            path: 'separate',
            component: () => import('@/views/enrollment/separate'),
            meta: { 
                title: '单独招生'
            }
        },
        {
            path: 'tridirectional',
            component: () => import('@/views/enrollment/tridirectional'),
            meta: { 
                title: '三定向'
            }
        },
        {
            path: 'vocational',
            component: () => import('@/views/enrollment/vocational'),
            meta: { 
                title: '高职扩招'
            }
        },
        //////////////////////////////////////////////////////////////////
        {
            path: 'information/:aid',
            component: () => import('@/views/enrollment/information'),
            meta: { 
                title: '招生信息 -- 详细'
            }
        },
        {
            path: 'brochure/:aid',
            component: () => import('@/views/enrollment/brochure'),
            meta: { 
                title: '招生简章', 
            },
        },
        {
            path: 'professional/:aid',
            component: () => import('@/views/enrollment/professional'),
            meta: { 
                title: '专业介绍'
            }
        },
        {
            path: 'separate/:aid',
            component: () => import('@/views/enrollment/separate'),
            meta: { 
                title: '单独招生 -- 详细'
            }
        },
        {
            path: 'tridirectional/:aid',
            component: () => import('@/views/enrollment/tridirectional'),
            meta: { 
                title: '三定向 -- 详细'
            }
        },
        {
            path: 'vocational/:aid',
            component: () => import('@/views/enrollment/vocational'),
            meta: { 
                title: '高职扩招 -- 详细'
            }
        },
        {
            path: 'search',
            component: () => import('@/views/search'),
            meta: { 
                title: '搜索'
            }
        },

    ]
};
//
export default enrollmentInformation