import axios from 'axios'
import vant from 'vant'
/* eslint-disable */
(function(window){
    var u = {
        UrlFix:process.env.VUE_APP_BASE_API,
        AdminUrl:process.env.VUE_APP_ADMIN_URL,
        data:{
            menu:{},
            banner:[]
        }
    };
    var isAndroid = (/android/gi).test(navigator.appVersion);
    var uzStorage = function(){
        var ls = window.localStorage;
        if(isAndroid){
           ls = os.localStorage();
        }
        return ls;
    };
    var toString = Object.prototype.toString;
    //
    u.trim = function(str){
        if(String.prototype.trim){
            return str == null ? "" : String.prototype.trim.call(str);
        }else{
            return str.replace(/(^\s*)|(\s*$)/g, "");
        }
    };
    u.trimAll = function(str){
        return str.replace(/\s*/g,'');
    };
    u.isElement = function(obj){
        return !!(obj && obj.nodeType == 1);
    };
    u.isArray = function(obj){
        if(Array.isArray){
            return Array.isArray(obj);
        }else{
            return obj instanceof Array;
        }
    };
    u.isEmptyObject = function(obj){
        if(JSON.stringify(obj) === '{}'){
            return true;
        }
        return false;
    };
    u.addEvt = function(el, name, fn, useCapture){
        if(!u.isElement(el)){
            console.warn('u.addEvt Function need el param, el param must be DOM Element');
            return;
        }
        useCapture = useCapture || false;
        if(el.addEventListener) {
            el.addEventListener(name, fn, useCapture);
        }
    };
    u.rmEvt = function(el, name, fn, useCapture){
        if(!u.isElement(el)){
            console.warn('u.rmEvt Function need el param, el param must be DOM Element');
            return;
        }
        useCapture = useCapture || false;
        if (el.removeEventListener) {
            el.removeEventListener(name, fn, useCapture);
        }
    };
    u.one = function(el, name, fn, useCapture){
        if(!u.isElement(el)){
            console.warn('u.one Function need el param, el param must be DOM Element');
            return;
        }
        useCapture = useCapture || false;
        var that = this;
        var cb = function(){
            fn && fn();
            that.rmEvt(el, name, cb, useCapture);
        };
        that.addEvt(el, name, cb, useCapture);
    };
    u.dom = function(el, selector){
        if(arguments.length === 1 && typeof arguments[0] == 'string'){
            if(document.querySelector){
                return document.querySelector(arguments[0]);
            }
        }else if(arguments.length === 2){
            if(el.querySelector){
                return el.querySelector(selector);
            }
        }
    };
    u.domAll = function(el, selector){
        if(arguments.length === 1 && typeof arguments[0] == 'string'){
            if(document.querySelectorAll){
                return document.querySelectorAll(arguments[0]);
            }
        }else if(arguments.length === 2){
            if(el.querySelectorAll){
                return el.querySelectorAll(selector);
            }
        }
    };
    u.byId = function(id){
        return document.getElementById(id);
    };
    u.first = function(el, selector){
        if(arguments.length === 1){
            if(!u.isElement(el)){
                console.warn('u.first Function need el param, el param must be DOM Element');
                return;
            }
            return el.children[0];
        }
        if(arguments.length === 2){
            return this.dom(el, selector+':first-child');
        }
    };
    u.last = function(el, selector){
        if(arguments.length === 1){
            if(!u.isElement(el)){
                console.warn('u.last Function need el param, el param must be DOM Element');
                return;
            }
            var children = el.children;
            return children[children.length - 1];
        }
        if(arguments.length === 2){
            return this.dom(el, selector+':last-child');
        }
    };
    u.eq = function(el, index){
        return this.dom(el, ':nth-child('+ index +')');
    };
    u.not = function(el, selector){
        return this.domAll(el, ':not('+ selector +')');
    };
    u.prev = function(el){
        if(!u.isElement(el)){
            console.warn('u.prev Function need el param, el param must be DOM Element');
            return;
        }
        var node = el.previousSibling;
        if(node.nodeType && node.nodeType === 3){
            node = node.previousSibling;
            return node;
        }
    };
    u.next = function(el){
        if(!u.isElement(el)){
            console.warn('u.next Function need el param, el param must be DOM Element');
            return;
        }
        var node = el.nextSibling;
        if(node.nodeType && node.nodeType === 3){
            node = node.nextSibling;
            return node;
        }
    };
    u.closest = function(el, selector){
        if(!u.isElement(el)){
            console.warn('u.closest Function need el param, el param must be DOM Element');
            return;
        }
        var doms, targetDom;
        var isSame = function(doms, el){
            var i = 0, len = doms.length;
            for(i; i<len; i++){
                if(doms[i].isSameNode(el)){
                    return doms[i];
                }
            }
            return false;
        };
        var traversal = function(el, selector){
            doms = u.domAll(el.parentNode, selector);
            targetDom = isSame(doms, el);
            while(!targetDom){
                el = el.parentNode;
                if(el != null && el.nodeType == el.DOCUMENT_NODE){
                    return false;
                }
                traversal(el, selector);
            }

            return targetDom;
        };
        return traversal(el, selector);
    };
    u.contains = function(parent,el){
        var mark = false;
        if(el === parent){
            mark = true;
            return mark;
        }else{
            do{
                el = el.parentNode;
                if(el === parent){
                    mark = true;
                    return mark;
                }
            }while(el === document.body || el === document.documentElement);

            return mark;
        }
    };
    u.remove = function(el){
        if(el && el.parentNode){
            el.parentNode.removeChild(el);
        }
    };
    u.attr = function(el, name, value){
        if(!u.isElement(el)){
            console.warn('u.attr Function need el param, el param must be DOM Element');
            return;
        }
        if(arguments.length == 2){
            return el.getAttribute(name);
        }else if(arguments.length == 3){
            el.setAttribute(name, value);
            return el;
        }
    };
    u.removeAttr = function(el, name){
        if(!u.isElement(el)){
            console.warn('u.removeAttr Function need el param, el param must be DOM Element');
            return;
        }
        if(arguments.length === 2){
            el.removeAttribute(name);
        }
    };
    u.hasCls = function(el, cls){
        if(!u.isElement(el)){
            console.warn('u.hasCls Function need el param, el param must be DOM Element');
            return;
        }
        if(el.className.indexOf(cls) > -1){
            return true;
        }else{
            return false;
        }
    };
    u.addCls = function(el, cls){
        if(!u.isElement(el)){
            console.warn('u.addCls Function need el param, el param must be DOM Element');
            return;
        }
        if('classList' in el){
            el.classList.add(cls);
        }else{
            var preCls = el.className;
            var newCls = preCls +' '+ cls;
            el.className = newCls;
        }
        return el;
    };
    u.removeCls = function(el, cls){
        if(!u.isElement(el)){
            console.warn('u.removeCls Function need el param, el param must be DOM Element');
            return;
        }
        if('classList' in el){
            el.classList.remove(cls);
        }else{
            var preCls = el.className;
            var newCls = preCls.replace(cls, '');
            el.className = newCls;
        }
        return el;
    };
    u.toggleCls = function(el, cls){
        if(!u.isElement(el)){
            console.warn('u.toggleCls Function need el param, el param must be DOM Element');
            return;
        }
       if('classList' in el){
            el.classList.toggle(cls);
        }else{
            if(u.hasCls(el, cls)){
                u.removeCls(el, cls);
            }else{
                u.addCls(el, cls);
            }
        }
        return el;
    };
    u.val = function(el, val){
        if(!u.isElement(el)){
            console.warn('u.val Function need el param, el param must be DOM Element');
            return;
        }
        if(arguments.length === 1){
            switch(el.tagName){
                case 'SELECT':
                    var value = el.options[el.selectedIndex].value;
                    return value;
                    break;
                case 'INPUT':
                    return el.value;
                    break;
                case 'TEXTAREA':
                    return el.value;
                    break;
            }
        }
        if(arguments.length === 2){
            switch(el.tagName){
                case 'SELECT':
                    el.options[el.selectedIndex].value = val;
                    return el;
                    break;
                case 'INPUT':
                    el.value = val;
                    return el;
                    break;
                case 'TEXTAREA':
                    el.value = val;
                    return el;
                    break;
            }
        }

    };
    u.prepend = function(el, html){
        if(!u.isElement(el)){
            console.warn('u.prepend Function need el param, el param must be DOM Element');
            return;
        }
        el.insertAdjacentHTML('afterbegin', html);
        return el;
    };
    u.append = function(el, html){
        if(!u.isElement(el)){
            console.warn('u.append Function need el param, el param must be DOM Element');
            return;
        }
        el.insertAdjacentHTML('beforeend', html);
        return el;
    };
    u.before = function(el, html){
        if(!u.isElement(el)){
            console.warn('u.before Function need el param, el param must be DOM Element');
            return;
        }
        el.insertAdjacentHTML('beforebegin', html);
        return el;
    };
    u.after = function(el, html){
        if(!u.isElement(el)){
            console.warn('u.after Function need el param, el param must be DOM Element');
            return;
        }
        el.insertAdjacentHTML('afterend', html);
        return el;
    };
    u.html = function(el, html){
        if(!u.isElement(el)){
            console.warn('u.html Function need el param, el param must be DOM Element');
            return;
        }
        if(arguments.length === 1){
            return el.innerHTML;
        }else if(arguments.length === 2){
            el.innerHTML = html;
            return el;
        }
    };
    u.text = function(el, txt){
        if(!u.isElement(el)){
            console.warn('u.text Function need el param, el param must be DOM Element');
            return;
        }
        if(arguments.length === 1){
            return el.textContent;
        }else if(arguments.length === 2){
            el.textContent = txt;
            return el;
        }
    };
    u.offset = function(el){
        if(!u.isElement(el)){
            console.warn('u.offset Function need el param, el param must be DOM Element');
            return;
        }
        var sl = Math.max(document.documentElement.scrollLeft, document.body.scrollLeft);
        var st = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
        var rect = el.getBoundingClientRect();
        return {
            l: rect.left + sl,
            t: rect.top + st,
            w: el.offsetWidth,
            h: el.offsetHeight
        };
    };
    u.css = function(el, css){
        if(!u.isElement(el)){
            console.warn('u.css Function need el param, el param must be DOM Element');
            return;
        }
        if(typeof css == 'string' && css.indexOf(':') > 0){
            el.style && (el.style.cssText += ';' + css);
        }
    };
    u.cssVal = function(el, prop){
        if(!u.isElement(el)){
            console.warn('u.cssVal Function need el param, el param must be DOM Element');
            return;
        }
        if(arguments.length === 2){
            var computedStyle = window.getComputedStyle(el, null);
            return computedStyle.getPropertyValue(prop);
        }
    };
    u.jsonToStr = function(json){
        if(typeof json === 'object'){
            return JSON && JSON.stringify(json);
        }
    };
    u.strToJson = function(str){
        if(typeof str === 'string'){
            return JSON && JSON.parse(str);
        }
    };
    u.setStorage = function(key, value){
        if(arguments.length === 2){
            var v = value;
            if(typeof v == 'object'){
                v = JSON.stringify(v);
                v = 'obj-'+ v;
            }else{
                v = 'str-'+ v;
            }
            var ls = uzStorage();
            if(ls){
                ls.setItem(key, v);
            }
        }
    };
    u.getStorage = function(key){
        var ls = uzStorage();
        if(ls){
            var v = ls.getItem(key);
            if(!v){return;}
            if(v.indexOf('obj-') === 0){
                v = v.slice(4);
                return JSON.parse(v);
            }else if(v.indexOf('str-') === 0){
                return v.slice(4);
            }
        }
    };
    u.rmStorage = function(key){
        var ls = uzStorage();
        if(ls && key){
            return ls.removeItem(key);
        }
        return false
    };
    u.clearStorage = function(){
        var ls = uzStorage();
        if(ls){
            return ls.clear();
        }
        return true
    };
    /**
     * Determine if a value is an Array
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is an Array, otherwise false
     */
    u.isArray = function(val) {
        return toString.call(val) === '[object Array]';
    }
    /**
     * Determine if a value is an ArrayBuffer
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is an ArrayBuffer, otherwise false
     */
    u.isArrayBuffer = function(val) {
        return toString.call(val) === '[object ArrayBuffer]';
    }
    /**
     * Determine if a value is a FormData
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is an FormData, otherwise false
     */
    u.isFormData = function(val) {
        return (typeof FormData !== 'undefined') && (val instanceof FormData);
    }
    /**
     * Determine if a value is a view on an ArrayBuffer
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a view on an ArrayBuffer, otherwise false
     */
    u.isArrayBufferView = function(val) {
        var result;
        if ((typeof ArrayBuffer !== 'undefined') && (ArrayBuffer.isView)) {
            result = ArrayBuffer.isView(val);
        } else {
            result = (val) && (val.buffer) && (val.buffer instanceof ArrayBuffer);
        }
        return result;
    }
    /**
     * Determine if a value is a String
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a String, otherwise false
     */
    u.isString = function(val) {
        return typeof val === 'string';
    }
    /**
     * Determine if a value is a Number
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a Number, otherwise false
     */
    u.isNumber = function(val) {
        return typeof val === 'number';
    }
    /**
     * Determine if a value is undefined
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if the value is undefined, otherwise false
     */
    u.isUndefined = function(val) {
        return typeof val === 'undefined';
    }
    /**
     * Determine if a value is an Object
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is an Object, otherwise false
     */
    u.isObject = function(val) {
        return val !== null && typeof val === 'object';
    }
    /**
     * Determine if a value is a Date
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a Date, otherwise false
     */
    u.isDate = function(val) {
        return toString.call(val) === '[object Date]';
    }
    /**
     * Determine if a value is a File
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a File, otherwise false
     */
    u.isFile = function(val) {
      return toString.call(val) === '[object File]';
    }
    /**
     * Determine if a value is a Blob
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a Blob, otherwise false
     */
    u.isBlob = function(val) {
        return toString.call(val) === '[object Blob]';
    }
    /**
     * Determine if a value is a Function
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a Function, otherwise false
     */
    u.isFunction = function(val) {
        return toString.call(val) === '[object Function]';
    }
    /**
     * Determine if a value is a Stream
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a Stream, otherwise false
     */
    u.isStream = function(val) {
        return u.isObject(val) && isFunction(val.pipe);
    }
    /**
     * Determine if a value is a URLSearchParams object
     *
     * @param {Object} val The value to test
     * @returns {boolean} True if value is a URLSearchParams object, otherwise false
     */
    u.isURLSearchParams = function(val) {
        return typeof URLSearchParams !== 'undefined' && val instanceof URLSearchParams;
    }
    /**
     * Determine if we're running in a standard browser environment
     *
     * This allows axios to run in a web worker, and react-native.
     * Both environments support XMLHttpRequest, but not fully standard globals.
     *
     * web workers:
     *  typeof window -> undefined
     *  typeof document -> undefined
     *
     * react-native:
     *  typeof document.createElement -> undefined
     */
    u.isStandardBrowserEnv = function() {
        return (
            typeof window !== 'undefined' &&
            typeof document !== 'undefined' &&
            typeof document.createElement === 'function'
        );
    }
    /**
     * Iterate over an Array or an Object invoking a function for each item.
     *
     * If `obj` is an Array callback will be called passing
     * the value, index, and complete array for each item.
     *
     * If 'obj' is an Object callback will be called passing
     * the value, key, and complete object for each property.
     *
     * @param {Object|Array} obj The object to iterate
     * @param {Function} fn The callback to invoke for each item
     */
    u.forEach = function(obj, fn) {
        // Don't bother if no value provided
        if (obj === null || typeof obj === 'undefined') {
            return;
        }
        // Force an array if not already something iterable
        if (typeof obj !== 'object' && !u.isArray(obj)) {
            /*eslint no-param-reassign:0*/
            obj = [obj];
        }
        if (u.isArray(obj)) {
            // Iterate over array values
            for (var i = 0, l = obj.length; i < l; i++) {
                fn.call(null, obj[i], i, obj);
            }
        } else {
            // Iterate over object keys
            for (var key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    fn.call(null, obj[key], key, obj);
                }
            }
        }
    }
    /************************************
     * merge({foo: 123}, {foo: 456});
     * ---
     * @returns {Object} 
     */
    u.merge = function() {
        var result = {};
        function assignValue(val, key) {
            if (typeof result[key] === 'object' && typeof val === 'object') {
                result[key] = u.merge(result[key], val);
            } else {
                result[key] = val;
            }
        }
        for (var i = 0, l = arguments.length; i < l; i++) {
            u.forEach(arguments[i], assignValue);
        }
        return result;
    }
    //排序
    u.ksort = function(o) {
        let dat = {},
        keys = Object.keys(o);
        keys.sort();
        keys.forEach((key)=>{
            dat[key] = o[key];
        })
        return dat;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    u.COMPONET= {};
    axios.defaults.withCredentials = true;
    //
    u.get = function(url,param,fn){
        if(u.isFunction(param)){
            fn = param;
            param = {};
        }
        if(!url){
            return console.warn('url is null!');;
        }
        url = u.resetUrl(url);
        axios.get(url, param).then(function (rst) {
            if(!rst.data){
                console.warn('url:'+url);
                return vant.Toast.fail('AJAX:空数据');
            }
            if(!u.isObject(rst.data)){
                 console.warn('url:'+url);
                return vant.Toast.fail('AJAX:数据出错');
            }
            if(u.isFunction(fn)){
                return fn(rst.data)
            }
            if(rst.data.code<0){
                return u.reLogin(true);
            }
        }).catch((err)=>{
            console.log(url,err);
        });
    };
    u.post = function(url,param,fn,bool){
        if(bool){
            let duration = 15000;
            vant.Toast.loading({
                forbidClick: true,
                loadingType: 'spinner',
                duration: duration
            });
        }
        if(!url){
            return console.warn('url is null!');;
        }
        url = u.resetUrl(url);
        axios.post(url, param).then((rst)=>{
            if(bool){
                vant.Toast.clear();
            }
            if(!rst.data){
                console.warn('url:'+url);
                return vant.Toast.fail('AJAX:空数据');
            }
            if(!u.isObject(rst.data)){
                console.warn('url :'+url);
                return vant.Toast.fail('AJAX:数据出错');
            }
            if(u.isFunction(fn)){
                return fn(rst.data)
            }
            if(rst.data.code<0){
                return u.reLogin(true);
            }
        }).catch((err)=>{
            if(bool){
                vant.Toast.clear();
            }
            console.log(url,err);
        });
    };
    u.resetUrl = function(url){
        if(!url){
            return vant.Toast.fail('URL IS NUll');
        }
        return (url.indexOf('http')===0 || url.indexOf('.')===0 || (url.indexOf('/')===0)) ? url:u.UrlFix+'/'+url;
    }
    u.reLogin = function(bool){
        vant.Dialog.alert({
            message: '登录超时,请重新登录',
            theme: 'round-button',
        }).then(() => {
            return u.href('login');
        });
    };
    u.Load = function(file){
        if(!file || !u.isString(file)){
            return;
        }
        let mkId = (path)=>{
            return path.replace(/\./g,'').replace(/\/+/g,'_');
        }
        let ajax = (path,fn,call)=>{
            axios.get(path+'?'+Math.random()).then((rst)=>{
                let text = rst.data;
                let id = mkId(path);
                let item = u.byId(id);
                if(item){
                    u.remove(item);
                }
                if (path.indexOf(".js")>0){
                    item = document.createElement('script');
                    item.language = "javascript";  
                    item.type = "text/javascript";
                    item.id = id; 
                } else if (path.indexOf(".css")>0){
                    item = document.createElement("style")
                    item.type = "text/css";
                    item.id = id;
                }
                try{  
                    //IE8以及以下不支持这种方式，需要通过text属性来设置  
                    item.appendChild(document.createTextNode(text));  
                }catch (e){  
                    item.text = text;
                }
                let obj = document.getElementsByTagName("head")[0];  
                obj.appendChild(item);
                //
                if(u.isFunction(call)){
                    call(rst.data);
                }
                if(u.isFunction(fn)){
                    return fn(rst.data);
                }
            }).catch((err)=>{
                console.warn(err)
                vant.Toast.fail('出错了!')
            });
        };
        this.head = (fn)=>{
            let arr = [];
            if(u.isString(file)){
                arr.push(file);
            }
            if(u.isArray(file)){
                arr = file;
            }
            let len = arr.length;
            for (let n = 0; n < len; n++) {
                let x = arr.shift()
                if(arr.length>0){
                    return ajax(x);
                }
                ajax(x,fn);
            }
        };
        return this;
    },
    u.clone = function(Obj){
        var buf;
        if(Obj instanceof Array){
            buf=[];
            var i=Obj.length;
            while(i--){
                buf[i]= u.clone(Obj[i]);
            }
            return buf;
        }
        else if(Obj instanceof Object){
            buf={};
            for(var k in Obj){
                buf[k]= u.clone(Obj[k]);
            }
            return buf;
        }else{
            return Obj;
        }
    }
    //日期格式化
    u.date = function(timestamp,format) {
        !format && (format ='Y-m-d H:i:s');
        var jsdate =(timestamp && !isNaN(timestamp)) ? new Date(timestamp * 1000) : new Date();
        var pad = function (n, c) {
            if ((n = n + "").length < c) {
                return new Array(++c - n.length).join("0") + n;
            } else {
                return n;
            }
        };
        var f={
            Y: function () {
                return jsdate.getFullYear()
            },
            y: function () {
                return (jsdate.getFullYear() + "").slice(2)
            },
            m: function () {
                let dt = jsdate.getMonth() + 1;
                return pad(dt, 2)
            },
            d: function () {
                let dt = jsdate.getDate();
                return pad(dt, 2)
            },
            H: function () {
                return pad(jsdate.getHours(), 2)
            },
            i: function () {
                return pad(jsdate.getMinutes(), 2)
            },
            s: function () {
                return pad(jsdate.getSeconds(), 2)
            },
        }
        return format.replace(/[\\]?([a-zA-Z])/g, function (t, s) {
            if(f[s]){
                return f[s]();
            }
            return s;
        });
    }
    //生成URL
    u.MKUrl = function(name,fix){
        if(!name){
            return '';
        }
        let len = 60;
        name = name.toLowerCase();
        let list = name.split(' ');
        let url='';
        let word='';
        for(let i in list){
            word = u.trim(list[i].replace(/[\W_]+/g,'-'));
            if(word==''){
                continue;
            }
            url+=(url?'-':'')+word;
        }
        return url.substr(0,len);
    };
    u.hashID = function(n,def){
        n = n || 2;
        let _hash = window.location.hash.replace(/#\/([^?]+).*/, '$1');
        let _id = _hash.split('/')[n];
        return _id || def;
    }
    u.param = function(name,def){
         let query = window.location.hash.replace(/#\/[^?]+(\?.*)?/, '$1');
         if(!query){
            return def || null;
         }
         query = query.substr(1);
         let list = query.split('&');
         let out = {
            nil:[]
         };
         for(let i in list){
            let v = list[i];
            let arr = v.split('=');
            if(arr.length==1){
                out.nil.push(arr[0]);
                continue;
            }
            out[arr[0]] = arr[1];
         }

         return name?(out[name] || def):out;
    }
    //
    u.randx = function(num,bool){
        if(isNaN(num)){
            return;
        }
        let string = 'ABCDEFGHJKLMNPQRSTUVWXYZ'+(!bool?'23456789':'');
        let list = string.split('');
        let i;
        let rst = '';
        let len = string.length-1;
        for(let n=0;n<num;n++){
            i = Math.round(Math.random()*len);
            rst += list[i];
        }
        return rst;
    }
    //唯一数字码0-19位
    u.uniqid = function(num){
        num = isNaN(num)?15:num;
        num<10 && (num =10);
        let str = new Date().getTime();
        for(let n=0;n<num-9;n++){
            str+=Math.round(Math.random()*9);
        }
        return str; 
    };
    //
    u.href = function(name,target){
        name = name || '';
        if(name.indexOf('http')===0 ){
            return window.open(name,target);
        }
        window.location.href='#/'+name;
    }
    u.toast = function(dat){
        type = dat.type || {};
        msg = dat.msg || '';
        if(type=='fail'){
            return vant.Toast.fail(msg);
        }
        if(type=='success'){
            return vant.Toast.success(msg);
        }
        if(type=='clear'){
            return vant.Toast.clear();
        }
        return;
    }
    //首字大写
    u.ucfirst = function(name){
        if(!u.isString(name)){
            return name;
        }
        return name.substr(0,1).toUpperCase() + name.substr(1)
    }
    u.autoImage = function(img){
        if(!img){
            return;
        }
        let prev = img.parentNode;
        prev.style.textAlign='center';
        let WNum = prev.offsetWidth;
        let HNum = prev.offsetHeight;
        //---
        var obj = document.createElement("img");
        obj.src = img.src;
        obj.onload = function(){
            if(obj.width/obj.height < WNum/HNum){
                if(obj.height<HNum){
                    img.style.height= obj.height+'px';
                    img.style.width = obj.width+'px';
                    img.style.marginTop = (HNum-obj.height)/2+'px';
                    img.style.marginLeft=(WNum-obj.width)/2+'px';
                }else{
                    img.style.height= HNum+'px';
                    let num = obj.width/(obj.height/HNum);
                    img.style.width =  num+'px';
                    img.style.marginLeft = (WNum-num)/2+'px';
                }
                return;
            }
            if(obj.width<WNum){
                img.style.width = obj.width+'px';
                img.style.height= obj.height+'px';
                img.style.marginLeft=(WNum-obj.width)/2+'px';
                img.style.marginTop=(HNum-obj.height)/2+'px';
            }else{
                img.style.width = WNum+'px';
                let num = obj.height/(obj.width/WNum);
                img.style.height=  num+'px';
                img.style.marginTop = (HNum-num)/2+'px'
            } 
        }
    }
    u.systemSetting = function(sys,that){
        if(!u.isObject(sys)){
            sys = {};
        }
        let HLogo = u.byId('header-logo');
        if(HLogo && sys.header_logo){
            HLogo.src = sys.header_logo;
        }
        let HMotto = u.byId('header-motto');
        if(HMotto && sys.header_motto){
            HMotto.src = sys.header_motto;
        }
        let FLogo = u.byId('footer-logo');
        if(FLogo && sys.footer_logo){
            FLogo.src = sys.footer_logo;
        }
        let FMotto = u.byId('footer-motto');
        if(FMotto && sys.footer_motto){
            FMotto.src = sys.footer_motto;
        }
        let SBanner = u.byId('system-banner');
        if(SBanner && sys.banner.length>0){
            let pic = sys.banner.shift();
            SBanner.style.background = "url("+pic+") no-repeat 50% 0px";
            SBanner.style.backgroundSize = "1920px 100%";
        }
        //////////////////////////////////////////////////////
        var TORGB = (str)=>{
            if(!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(str)){
                return str;
            }
            str = str.toLowerCase().replace('#','');
            if(str.length==3){
                let arr = str.split();
                str = arr[0]+arr[0]+arr[1]+arr[1]+arr[2]+arr[2]
            }
            let arr =[];
            for(let i=0;i<6;i+=2){
                let s = str.slice(i,i+2);
                arr.push(parseInt("0x"+s))
            }
            return arr.join(',');
        }
        let style = document.getElementsByTagName('body')[0].style;
        let HColor = TORGB(sys.header_color)
        if(HColor){
            style.setProperty('--HOME_HEADER_COLOR', HColor);
            style.setProperty('--van-primary-color', 'rgb('+HColor+')');
        }
        let BColor = TORGB(sys.body_color)
        if(BColor){
            style.setProperty('--HOME_BODY_COLOR', BColor);
        }
        let FColor = TORGB(sys.footer_color)
        if(FColor){
            style.setProperty('--HOME_FOOTER_COLOR', FColor);
        }
        //////////////////////////////////////////////////////
        let D =  u.store('user');
        that.user = u.isObject(D)?D:{};
    }

    //加入收藏
    u.addFavorite = function(surl, title){
        surl = surl || window.location;
        title = title || document.title || '江西家业工程职业学院';
        surl = encodeURI(surl);
        try {
            window.external.addFavorite(surl, title);
            vant.Toast.success('加入收藏成功');
        } catch (e) {
            try {
                window.sidebar.addPanel(title, surl, "");
            } catch (e) {
                vant.Dialog.alert({
                    message: '加入收藏失败，请使用Ctrl+D进行添加,或手动在浏览器里进行设置~',
                    theme: 'round-button',
                }).then(() => {
                    return;
                });
            }
        }
    }
    //设为首页
    u.setHomePage = function(obj){
        let vrl = window.location;
        try{
            obj.style.behavior='url(#default#homepage)';
            obj.setHomePage(vrl);
            vant.Toast.success('设置首页成功');
        } catch(e) {console.log(e)
            if(window.netscape) {
                try {
                    netscape.security.PrivilegeManager.enablePrivilege("UniversalXPConnect"); 
                } 
                catch (e) { 
                    vant.Dialog.alert({
                        message: "此操作被浏览器拒绝！\n请在浏览器地址栏输入“about:config”并回车\n然后将[signed.applets.codebase_principal_support]设置为'true'",
                        theme: 'round-button',
                    }).then(() => {
                        return;
                    });
                }
                var prefs = Components.classes['@mozilla.org/preferences-service;1'].getService(Components.interfaces.nsIPrefBranch);
                prefs.setCharPref('browser.startup.homepage',vrl);
             }
        }
    }
    //存储
    u.store = function(key,data){
        if(!key){
            return;
        }
        if(data!==undefined){
            return sessionStorage.setItem(key,JSON.stringify(data));
        }
        let string_json = sessionStorage.getItem(key);
        if(!string_json){
            return string_json;
        }
        return JSON.parse(string_json);
    }
    //下载
    u.download = function(url){
        let duration = 25000;
        vant.Toast.loading({
            forbidClick: true,
            loadingType: 'spinner',
            duration: duration
        });
        let filename = url.split('/').pop();
        fetch(url).then(res => res.blob().then(blob => {
            // 动态创建a标签，防止下载大文件时，用户没看到下载提示连续点击
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            vant.Toast.clear();
        }));
    }
    //--------------
    window.u = u;
})(window);
////////////////////////////////////////////////////////////////////////
export default u;

