import Layout from '@/layout'

//专题专栏
const specialColumn = {
    path: '/party',
    component: Layout,
    redirect: '/party/education',
    alwaysShow: true,
    meta: { 
        title: '专题专栏',
    },
    children: [
        ////////////////////////////////////////////////////////////////////
        {
            path: ':view',
            component: () => import('@/views/party/index'),
            meta: { 
                title: '栏目' 
            }
        },
        {
            path: ':view/:aid',
            component: () => import('@/views/party/index'),
            meta: { 
                title: '详细' 
            }
        },
    ]
};
//
export default specialColumn