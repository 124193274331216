
<template>
<div class='qun-video'>
    <videoPlay 
    v-bind="options" 
    @play="onPlay"
    @pause="onPause" 
    @timeupdate="onTimeupdate" 
    @canplay="onCanplay" 
    ref="videoPlay"
    />
</div>
</template>
<script>
/* eslint-disable */
import 'vue3-video-play/dist/style.css'
import { videoPlay } from 'vue3-video-play'
export default {
    components: {
        videoPlay
    },
    props: {
        url:{
            type: String,
            default:null
        },
        cover:{
            type: String,
            default:null
        }
    },
    data () {
        return {
            options: {
				width: '100%', //播放器高度
				height: '100%', //播放器高度
				color: "#409eff", //主题色
				muted: false, //静音
				webFullScreen: false,
				speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
				autoPlay: false, //自动播放
				loop: false, //循环播放
				mirror: false, //镜像画面
				ligthOff: false,  //关灯模式
				volume: 0.3, //默认音量大小
				control: true, //是否显示控制器
				title: '', //视频名称
				src: '',//http://vjs.zencdn.net/v/oceans.mp4", //视频源
				poster: '', //封面
			}
        }
    },
    methods:{
		onPlay(ev){
            console.log('播放')
		},
		onPause(ev){
            console.log('暂停')
		},
		onTimeupdate(ev){
            console.log('时间更新')
		},
		onCanplay(ev){
            console.log('可以播放')
		},
        onClose(){
            this.$refs.videoPlay.pause();
        },
        onPlayer(url){
            this.options.src = url;
        }
    },
    created() {
        this.options.src = this.url;
        this.options.poster = this.cover;
    },
};
</script>
<style lang="scss" scoped>
.qun-video {
    max-width: 800px;
    min-width: 360px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
</style>
