import Layout from '@/layout/user'

//账号管理
const User = {
    path: '/user',
    component:Layout,
    redirect: '/user/login',
    alwaysShow: true,
    meta: {
        title: '用户',
    },
    children: [
        {
            path: 'login',
            component: () => import('@/views/user/login'),
            meta: { 
                title: '登录'
            }
        },
        {
            path: 'register',
            component: () => import('@/views/user/register'),
            meta: { 
                title: '注册'
            }
        },
    ]
}

export default User