import Layout from '@/layout'

//教学科研
const teachingScientific = {
    path: '/scientific',
    component: Layout,
    redirect: '/scientific/major',
    alwaysShow: true,
    meta: { 
        title: '教学科研',
    },
    children: [
        {
            path: ':name',
            component: () => import('@/views/scientific/index'),
            meta: { 
                title: ''
            }
        },
        {
            path: ':name/:aid',
            component: () => import('@/views/scientific/index'),
            meta: { 
                title: ''
            }
        },
        {
            path: 'reform/:name/:aid',
            component: () => import('@/views/scientific/index'),
            meta: { 
                title: ''
            }
        },
        // {
        //     path: 'major',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '专业设置'
        //     }
        // },
        //  ///////////////////////////////////////////////////////////////
        // {
        //     path: 'information',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '教学信息'
        //     }
        // },
        // {
        //     path: 'information/:aid',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '教学信息'
        //     }
        // },
        // {
        //     path: 'document',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '教学文件'
        //     }
        // },
        // {
        //     path: 'document/:aid',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '教学文件'
        //     }
        // },
        // ///////////////////////////////////////////////////////////////
        // {
        //     path: 'curriculum',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '精品课程'
        //     }
        // },
        // {
        //     path: 'achievement',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '学生成绩查询'
        //     }
        // },
        // {
        //     path: 'resources',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '课程资源库'
        //     }
        // },
        // {
        //     path: 'collect',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '教师风采'
        //     }
        // },
        // ///////////////////////////////////////////////////////////////
        // {
        //     path: 'competition',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '竞赛信息'
        //     }
        // },
        // {
        //     path: 'competition/:aid',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '竞赛信息'
        //     }
        // },
        // ///////////////////////////////////////////////////////////////
        // {
        //     path: 'adult',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '成人教育'
        //     }
        // },
        // {
        //     path: 'reform',
        //     component: () => import('@/views/scientific/index'),
        //     meta: { 
        //         title: '教改科研'
        //     }
        // }
    ]
};
//
export default teachingScientific